.b2b-edit-profile-web-header-button-container {
  display: flex;
  align-items: center;
}
.b2b-edit-profile-web-form-container {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.b2b-edit-profile-web-add-more-number-container {
  height: 48px;
  display: flex;
  align-items: center;
  width: 92%;
  justify-content: center;
  border: 1px dashed #989fab;
}
.b2b-edit-profile-web-add-more-number {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #989fab;
}
