.listHeader {
    font-weight: 600;
    color: black;
    padding-top: 20px;
    opacity: 0.5;
    text-transform: uppercase;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
