/* .marqueeInnerDiv {
  background-color: #1c87c9;
  color: #fff;
  padding: 5px;
} */
.marqueeText {
  /* -moz-animation: marquee 10s linear infinite; */
  /* -webkit-animation: marquee 10s linear infinite; */
  animation: animate 30s linear infinite;
  padding-left: 100%;
  display: inline-block;
}
@keyframes animate {
  100% {
    transform: translate(-100%, 0);
  }
}
