.daily-rates-web-rates-main-container {
  /* height: 35vh; */
  /* background: #9e9e9e; */
  /* padding: 110px 0px 0px 40px; */
  /* overflow: scroll; */
}
.daily-rates-web-daily-rates-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.daily-rates-web-rates-list-container {
  margin: 10px 18px;
  position: relative;
  width: 12%;
  height: 18vh;
  border-radius: 12px;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}
.daily-rates-web-three-line-image-container {
  position: absolute;
  height: 32%;
  right: 0px;
}
.daily-rates-web-show-price-container {
  position: absolute;
  top: 32%;
  height: 68;
  height: 68%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 16px;
  flex-direction: column;
}
.daily-rates-web-rates-list-main-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.daily-rates-web-bottom-message-show-container {
  position: absolute;
  bottom: 5px;
  height: 40vh;
  width: 70%;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0px 40px 0px 0px;
  padding: 20px 30px;
}
.daily-rates-web-order-text {
  font-size: 1.3em;
  color: #35425a;
}
.daily-rates-web-bottom-message {
  display: flex;
  flex-direction: column;
}
.daily-rates-web-bottom-more-feature-text {
  font-weight: bold;
  color: #35425a;
}
.daily-rates-web-bottom-message-text {
  font-size: 1em;
  color: #8b959a;
}
