/*<<<<<<<<<<<---------------- CSS FOR WEB Registration WEB VIEW ------------------->>>>>>>>*/
/* LEFT SCREEN--------------- */
.registration-web-view-left-container {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.registration-web-view-left-container-child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.registration-web-view-left-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20%;
}
.registration-web-view-left-portal-text {
  color: #b6bcd1;
  margin-top: -7px;
}
.registration-web-view-left-about-app-text {
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #35425a;
}
.registration-web-view-left-explore-benifits-container {
  margin-top: 25px;
}
.registration-web-view-left-show-benifits-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 0% 0%;
}
.registration-web-view-left-show-benifits-image-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.registration-web-view-left-booking-benifits-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration-web-view-left-explore-list-booking {
  padding: 3px;
  padding-left: 12px;
  width: 35%;
  background: white;
  align-items: center;
  display: flex;
  border-radius: 3px;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}
.registration-web-view-left-explore-primary-container {
  display: flex;
  margin-left: 12px;
  /* align-items: center; */
  flex-direction: column;
}
.registration-web-view-left-explore-live-order-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/* RIGHT SCREEN ----------*/
.registration-web-view-right-header {
  height: 56px;

  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
}
.registration-web-view-right-form-start {
  margin-left: 1%;
  position: relative;
  height: 90%;
  background: white;
  width: 70%;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

/* -------CSS FOR RegisterGstNumberWeb---------- */
.b2b-register-gst-detail-web-gst-test {
  font-size: 0.9em;
  color: #35425a;
}
.b2b-register-gst-detail-web-legal-name-container {
  display: flex;
  flex-direction: column;
}
.b2b-register-gst-detail-web-legal-name-text {
  color: #8b959a;
  font-size: 0.8em;
}
.b2b-register-gst-detail-web-legal-company-name-text {
  color: #35425a;
}
.b2b-register-gst-detail-web-trade-name-container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.b2b-register-gst-detail-web-central-jurisdiction-container {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.b2b-register-gst-detail-web-central-jurisdiction-child {
  display: flex;
  flex-direction: column;
}
.pan-img-label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pan-img-input[type="file"] {
  display: none;
}
/* -------CSS FOR RegisterGstDetailsWeb---------- */
/* .b2b-register-gst-detail-web-gst-test {
  font-size: 0.9em;
  color: #35425a;
}
.b2b-register-gst-detail-web-legal-name-container {
  display: flex;
  flex-direction: column;
}
.b2b-register-gst-detail-web-legal-name-text {
  color: #8b959a;
  font-size: 0.8em;
}
.b2b-register-gst-detail-web-legal-company-name-text {
  color: #35425a;
}
.b2b-register-gst-detail-web-trade-name-container {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.b2b-register-gst-detail-web-central-jurisdiction-container {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.b2b-register-gst-detail-web-central-jurisdiction-child {
  display: flex;
  flex-direction: column;
} */

/* -------CSS FOR RegisterAddressDetailsWeb---------- */

@media only screen and (max-width: 1100px) {
  #registerAddressDetailsWebMainBody {
    padding: 0px;
  }
}
/* -------CSS FOR RegisterPersonalInformationWeb---------- */

@media only screen and (max-width: 1100px) {
  #registerPersonalInformationWebMainBody {
    padding: 0px;
  }
}
/* -------CSS FOR registerLoginCredentialWebMainBody---------- */

@media only screen and (max-width: 1100px) {
  #registerLoginCredentialWebMainBody {
    padding: 30px 15px 18px 15px;
  }
}

/* -------CSS FOR RegisterCreatePasswordWeb---------- */
@media only screen and (max-width: 1100px) {
  #RegisterCreatePasswordWebMainBody {
    padding: 30px 15px 18px 15px;
  }
}
.b2b-register-create-password-web-strong-password-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 8px;
}

/* -------CSS FOR RegisterSuccessfullWeb---------- */

@media only screen and (max-width: 1100px) {
  #RegisterSuccessfullWebMainBody {
    padding: 30px 15px 18px 15px;
  }
}

.b2b-register-successful-web-show-company-details-div {
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 24%;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  padding-left: 21px;
  justify-content: center;
}
.b2b-register-successful-web-registration-info-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #8b959a;
  font-size: 0.8em;
}
.b2b-register-successful-web-registration-info-container-left-div {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.b2b-register-successful-web-registration-info-container-right-div {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  text-align: end;
}
.b2b-register-successful-web-message-show-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
}
.b2b-register-successful-web-show-text-bottom {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.b2b-register-successful-web-register-successfull-logout-div {
  margin-top: 10px;
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: center;
  width: 80%;
}
