.distributor-triangle-shape {
  width: 18px;
  height: 16px;
  background: #3d73dd;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  padding-left: 2px;
}
.distributor-triangle-shape:after {
  content: "";
  position: absolute;
  top: 16px;
  left: 1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #3d73dd transparent transparent transparent;
  border-width: 8px 9px 0px 8px;
}
