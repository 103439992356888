.b2b-mobile-edit-profile-header-container {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b2b-mobile-edit-profile-image-container {
  border-radius: 12px;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  height: 75%;
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b2b-mobile-edit-profile-form-start-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.b2b-mobile-edit-profile-add-more-number-container {
  height: 56px;
  display: flex;
  align-items: center;
  width: 93%;
  justify-content: center;
  border: 1px dashed #989fab;
}
.b2b-mobile-edit-profile-add-more-number {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #989fab;
}
