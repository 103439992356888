.b2b-profile-change-password-web-header-button-container {
  display: flex;
  align-items: center;
}
.b2b-profile-change-password-web-form-container {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
