.admin-profile-web-header-name-container {
  display: flex;
  align-items: center;
}
.admin-profile-web-header-name-text {
  font-size: 1.4em;
  margin-right: 15px;
}
.admin-profile-web-email-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.admin-profile-web-email-contanier {
  display: flex;
  flex-direction: column;
}
.admin-profile-web-gst-container {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.admin-profile-web-manage-button-container {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
