.login-header-image {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-text-div {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.login-form-start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-forgot-password-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-forgot-password-text {
  width: 80%;
  color: #4b87c5;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8em;
}
.login-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-dont-have-account {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-register-button-container {
  position: absolute;
  bottom: -18px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-benifits-button-container {
  /* margin-top: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-strong-password-div {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.login-create-new-password-susscessfull-container {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-change-password-text {
  font-size: 1.4em;
  color: #4b87c5;
}
.login-explore-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.login-explore-list {
  margin-bottom: 15px;
  padding: 10px;
  padding-left: 12px;
  width: 75%;
  background: white;
  align-items: center;
  display: flex;
  border-radius: 3px;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}
.login-explore-list-item {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.login-explore-title-style {
  font-size: 1.1em;
  color: #35425a;
}
.login-explore-subtitle-style {
  font-size: 0.9em;
  color: #b6bcd1;
}

/*<<<<<<<<<<<---------------- CSS FOR WEB LOGIN ------------------->>>>>>>>*/

/* LEFT SCREEN--------------- */
.login-web-left-container {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.login-web-left-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 20%;
}
.login-web-left-portal-text {
  color: #b6bcd1;
  margin-top: -7px;
}
.login-web-left-about-app-text {
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #35425a;
}
.login-web-right-container {
  width: 45%;
  background: rgb(92, 170, 175);
}
.login-web-explore-benifits-container {
  margin-top: 25px;
}
.login-web-left-container-child {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-web-show-benifits-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10% 0% 0%;
}
.login-web-show-benifits-image-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-web-booking-benifits-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.login-web-explore-list-booking {
  padding: 3px;
  padding-left: 12px;
  width: 35%;
  background: white;
  align-items: center;
  display: flex;
  border-radius: 3px;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  transition: transform 0.5s;
}
.login-web-explore-list-booking:hover {
  transform: scale(1.1);
}
.login-web-explore-primary-container {
  display: flex;
  margin-left: 12px;
  /* align-items: center; */
  flex-direction: column;
}
.login-web-explore-live-order-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/* RIGHT SCREEN ----------*/
.login-web-right-header {
  height: 56px;

  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
}
.login-web-form-start {
  margin-left: 1%;
  position: relative;
  height: 90%;
  background: white;
  width: 70%;
  box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}
.login-contact-us {
}
.login-web-login-text-10vh {
  position: relative;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-web-text {
  /* position: absolute;
  bottom: 1px; */
  font-size: 1.5em;
  color: #4b87c5;
  margin-top: 13px;
}
.login-web-verifynumber-text {
  color: #b6bcd1;
}
.login-web-form-container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}
.login-web-register-button-container {
  position: absolute;
  bottom: -18px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logon-web-forgot-password-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.logon-web-forgot-password-div {
  width: 65%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.powered-by-text {
  color: #b6bcd1;
  font-size: 0.9em;

  position: absolute;
  bottom: 70px;
}

.login-web-logo-container {
  position: absolute;
  bottom: 96px;
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  /* display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 95px; */
}
.login-web-dont-have-acc-text {
  margin-top: 15px;
  color: #b6bcd1;
  font-size: 1em;
  position: absolute;
  bottom: 40px;
}
.login-web-contact-us-container {
  /* margin-top: 5%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.login-web-contact-us {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 10px; */
  margin-left: 1%;
}
.login-web-verify-text {
  color: #b6bcd1;
  margin-top: 3px;
  text-align: center;
}
.login-strong-password-div {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.login-web-strong-password-div {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px 0px;
}
.login-web-password-changed-conatiner {
  width: 100%;
  display: flex;
  height: 20vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.login-web-password-changed-text {
  font-size: 1.4em;
  color: #4b87c5;
}
