.b2b-mobile-profile-setting-setting-text {
  font-size: 1.4em;
  padding: 25px 0px 0px 10px;
  color: #a3a3a3;
}

.b2b-mobile-profile-setting-bottom-navigation {
  height: 74px;
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.b2b-mobile-profile-setting-list-start {
  margin-top: 20px;
}
.b2b-mobile-profile-setting-logout-button-container {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
