.step {
  height: 7vh;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}
.v-stepper {
  position: relative;
}
.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.step .line {
  top: 24px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 4px solid #4b87c5;
}

.step .cancel-line {
  top: 24px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 4px solid red;
}
.step.completed .circle {
  visibility: visible;
  background-color: #4b87c5;
  border-color: #4b87c5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step .cancel {
  background-color: red;
  border: 3px solid red;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.step.completed .cancel {
  visibility: visible;
  /* background-color: #e22e2e; */
  border-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step.completed .line {
  border-left: 4px solid #4b87c5;
}

.step.active .circle {
  visibility: visible;
  border-color: #4b87c5;
}
.step.empty .circle {
  visibility: hidden;
}
.step.empty .line {
  top: 0;
  height: 150%;
}
.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1; /* behind the circle to completely hide */
}

.content {
  margin-left: 20px;
  display: inline-block;
  display: flex;
  flex-direction: column;
}
