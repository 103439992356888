.b2b-web-drawer-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}
.b2b-web-drawer-header-image-container {
  height: 63%;
  display: flex;
  width: 77%;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20px;
  /* background: white;
  height: 60%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  text-align: center; */
}
.b2b-web-drawer-header-image-style {
  width: 90%;
  /* height: auto; */
  object-fit: contain;
  height: 90%;
}
.b2b-web-drawer-online-portal-text {
  font-size: 0.5em;
  color: #35425a;
}
