.b2b-mobile-NO-notification-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b2b-mobile-no-notification-text {
  color: #35425a;
  margin-left: 10px;
}
.b2b-mobile-notification-header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b2b-mobile-notification-date-text {
  color: #989fab;
}
.b2b-mobile-notification-clear-all-text {
  color: #1b388b;
}
.b2b-mobile-another-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
