/* Global CSS for all register component */
.img-style-in-register {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.mobile-stepper-in-registration {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 56px;
}

/* Css for register personal information  */

.register-personal-info-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 45px;
}
.register-create-passward-type-passward-div {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 7px;
}
/* .register-personal-info-header-img {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-personal-info-form-div {
  flex: 8;
}
.register-personal-info-next-button-div {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.personal-info-text {
  padding-top: 15px;
}
.register-personal-form-start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

/* CSS for register-create-passward */
.register-create-passward-image-header {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.register-create-passward-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-strong-password-div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.logo-img-style-in-login-create-pass {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* .register-create-passward-header-img {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-create-passward-form-div {
  flex: 8;
}
.type-passward-text {
  padding-top: 15px;
}
.register-create-passward-form-start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-create-passward-next-button-div {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

/* CSS for register-select-category ------------*/
.register-select-category-header {
  height: 120px;
  position: relative;
}
.register-select-category-header-text {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;
}
/* .register-select-category-header-img {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.register-select-category {
  width: 100%;
  background: white;
  height: 12vh;
  border-radius: 15px;
  box-shadow: 2px 1px 1px 1px rgba(2, 0, 1, 0.08),
    1px 1px 6px 2px rgba(4, 5, 2, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  display: flex;

  align-items: center;
}
.register-select-category-child {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.register-radio-button-span {
  margin-left: 25px;
}
.register-ramark-needed-text {
  color: grey;
  font-size: 0.8em;
  margin-top: 5px;
}
.register-select-category-main {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 93%;
}
/* .select-category-container {
  padding-top: 50px;
} */
/* CSS for register-company-details */
.register-company-detail-header-img {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-company-detail-text-div {
  flex: 6;
}
.company-detail-text {
  padding-top: 15px;
}
.register-company-details {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-company-details-form-start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-add-more-contact {
  height: 56px;
  width: 95%;
  border: 1px dashed #9e9e9ed4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.register-add-more-span {
  display: flex;
  align-items: center;
  color: grey;
}

/* CSS for register-successfull */
.register-successfull-header-img {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-successful-main-div {
  flex: 8;
}
.register-successfull-text-div {
  padding-top: 15px;
}
.register-successfull-company-detail-show-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-successfull-company-detail {
  margin-top: 15px;
  width: 100%;
  background: white;
  height: 14vh;
  border-radius: 15px;
  box-shadow: 3px 5px 5px -1px rgba(0, 0, 0, 0.2),
    0px 2px 10px 2px rgba(2, 1, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;

  align-items: center;
}
.register-successfull-paper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.register-successful-paper-left {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.register-successfull-paper-right {
  margin-right: 30px;
}
.register-id-text {
  font-size: 0.9em;
  color: grey;
  margin-top: 4px;
}
.register-email-text {
  font-size: 0.8em;
  color: grey;
  margin-top: 4px;
}
.register-mobile-text {
  font-size: 0.7em;
  color: grey;
  margin-top: 6px;
}
.registeration-date-show-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registeration-date-show-left {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.registeration-date-show-right {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.text-style-firm {
  margin-top: 5px;
  color: #35425a;
}
.message-after-registration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #35425a;
  text-align: center;
}
.register-successfull-logout-div {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* CSS for registerGstNumber ------- */

.register-gst-number-header {
  height: 120px;
  position: relative;
}
.register-gst-number-header-text {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;
}

.gst-input-fiels-start {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.gst-info-details-header {
  height: 120px;
  position: relative;
}
.gst-info-detail-text {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;
}
.gst-number-main-container {
  display: flex;
  align-items: center;
}

.gst-legal-name-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.legal-name-text {
  color: grey;
}
.gst-jurisdiction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gst-state-juridiction-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  padding-right: 14px;
}

/* CSS for registerGstDetails ------------- */
/* 
.gst-info-details-header {
  height: 120px;
  position: relative;
}
.gst-info-detail-text {
  position: absolute;
  bottom: 35px;
  padding-left: 15px;

}
.gst-number-main-container {
  display: flex;
  align-items: center;
}

.gst-legal-name-container {
  display: flex;
  flex-direction: column;
}
.legal-name-text {
  color: grey;
}
.gst-jurisdiction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gst-state-juridiction-container {
  display: flex;
  flex-direction: column;
  padding-right: 14px;
} */

/* CSS For register address details ------------- */
.register-address-detail-header {
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: 13vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; */
}
.gst-number-in-address-details {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.register-address-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-address-form-container-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
  width: 90%;
}
.register-address-form-country-div {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 20px;
}
.register-address-form-state-div {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}
/* CSS FOR  REGISTER LOGIN CREDENTIAL------------ */
.register-login-credential-header {
  height: 120px;
  position: relative;
}
.register-login-credential-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
