.b2b-mobile-change-password-text-container {
  padding-top: 40px;
  font-size: 1.2em;
}
.b2b-mobile-change-password-form-start-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.b2b-mobile-change-password-save-button-container {
  position: absolute;
  bottom: 90px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
.b2b-mobile-change-password-cancel-button-container {
  position: absolute;
  bottom: 25px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
