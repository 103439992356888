.b2b-profile-setting-web-header-button-container {
  display: flex;
  align-items: center;
}
.b2b-profile-setting-web-list-container {
  width: 28%;
}
.b2b-profile-setting-web-logout-button-container {
  width: 28%;
  height: 56px;
  display: flex;

  align-items: center;
  padding-left: 20px;
}
