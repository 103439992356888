.OrderCard-orderDetailMainContainer-63 
.OrderCard-orderDetailChildContainer-64 
.OrderCard-orderDetailChildTopBar-65 
.OrderCard-orderDetailChildTopBar-423 
.MuiSvgIcon-root{
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}