.b2b-mobile-profile-bottom-navigation {
  height: 60px;
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.b2b-mobile-profile-header-container {
  height: 15vh;

  display: flex;
  align-items: center;
}
.b2b-mobile-profile-header-left-section {
  width: 77%;
  justify-content: space-around;
  height: 100%;
  display: flex;
  align-items: center;
}
.b2b-mobile-profile-header-right-section {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.b2b-mobile-profile-header-left-image-container {
  border-radius: 12px;
  /* box-shadow: 4px 1px 24px 0px rgba(0, 0, 0.5, 0.1),
    1px 1px 2px 2px rgba(2, 1, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.12); */
  height: 75%;
  width: 25%;
  transform: scale(0.7);
  justify-content: center;
  align-items: center;
  display: flex;
}
.b2b-mobile-profile-header-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.b2b-mobile-profile-user-name-text-style {
  color: #35425a;
  font-size: 1.5em;
  margin-left: 15px;
  /* white-space: nowrap; */
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
}
.b2b-mobile-profile-email-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  justify-content: center;
}
.b2b-mobile-profile-email-text {
  color: #8b959a;
}
.b2b-mobile-profile-email {
  color: #35425a;
  font-size: 1.4em;
}
.b2b-mobile-profile-manage-buyer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b2b-mobile-change-password-container {
  display: flex;
  justify-content: flex-start;
}
